
import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';

@Component
export default class Login extends Vue {

  public login() {
    this.$router.push({name: 'login'});
  }
  public async mounted() {
    let data = await api.activateUser(Number(this.$route.params.id))
      .then(function (response) {
        // console.log(response)
        return response.data;
      })
      .catch( function (error) {
        console.log("ERROR: " + error);
      });
      
  }
  
}
